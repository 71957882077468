<template>
    <div class="footer">
        <div class="bottom">
            <div class="name">联系电话：0576-XXXXXX</div>
            <div class="name">联系地址：台州市椒江区开发大道289号 中国移动台州分公司 11层政企部</div>
            <div class="name">邮编：317700</div>
        </div>
        <div class="bottom1"></div>
    </div>
</template>

<script>
export default {
    comments: {

    },
    props: {

    },
    created() {

    },
    mounted() {
    },
    data() {
        return {
            goPageList: [],
        }
    },
    methods: {

    }
}
</script>

<style scoped lang="less">
.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-top: 23px;
    width: 100%;
    height: 130px;
    background: #f5f7fa;

    .name {
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0px;
        color: #606266;
    }
}

.bottom1 {
    width: 100%;
    height: 130px;
}
</style>