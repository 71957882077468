import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/login.vue';

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect:"/login",
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  // 首页
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/home/home.vue')
  },
  // 首页
  {
    path: '/query',
    name: 'query',
    component: () => import(/* webpackChunkName: "about" */ '@/views/query/index.vue')
  },
  // 新闻资讯
  {
    path: '/information',
    name: 'information',
    component: () => import(/* webpackChunkName: "about" */ '@/views/information/information.vue')
  },
  // 新闻资讯详情
  {
    path:'/NewsDetail',
    name: 'NewsDetail',
    //也不使用懒加载，先在文件头部提前引入
    component: () => import(/* webpackChunkName: "about" */ '@/views/NewsDetail/NewsDetail.vue'),
  },
  // 融合创新
  {
    path: '/Integration',
    name: 'Integration',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Integration/Integration.vue')
  },
  // 关于我们
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutUs/AboutUs.vue')
  },
]



const router = new VueRouter({
  routes
})



router.beforeEach((to,from,next) => {
  // console.log(666, to)
  // console.log(777, from);
  if(to.path == '/login') {
    localStorage.setItem("token", "");
  } else {
    const token = localStorage.getItem('token')
    if(!token) {
      next('/login')
      return;
    }
  }
  next()
})

export default router
