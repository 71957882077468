import axios from 'axios';
import { Notification, MessageBox, Message, Loading } from 'element-ui'


// 是否显示重新登录
let isReloginShow;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const myAxios = axios.create({
    'Cache-Control': 'no-cache', // 不要缓存
    // baseURL: 'http://192.168.1.118:8080',
    baseURL: 'http://36.133.91.26:8080',
    // baseURL: 'https://www.luda-tec.com/prod-api',
    timeout: 1000 * 30, // 30秒超时
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', // 
    // 'Content-Type': 'multipart/form-data',
    // 'Content-Type': "application/x-www-form-urlencoded",
    // ▼提交之前，将数据形式转化
    // transformRequest: [function (data /* , headers */ ) {
    //     // if (['put', 'post', 'patch'].includes(config.method)) {}
    //     if (!data) return data; // 没有，直接返回
    //     return dataToFormData(data);
    //     // return data;
    // }],
});

// ▼转formData
// function dataToFormData(oData) {
//     const oFormData = new FormData();
//     for (const [key, val] of Object.entries(oData)) {
//         if (Array.isArray(val)) { //是
//             for (const oneItem of val) oFormData.append(key, oneItem);
//         } else {
//             if (val !== null) {
//                 oFormData.append(key, val);
//             }
//         }
//     }
//     return oFormData;
// }

// ▼请求拦截器
myAxios.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("token") // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
}, error => {
    return Promise.reject(error);
});

// ▼响应拦截器
myAxios.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    console.log(res.data.code);
    if (res.data.code === 0) {
        if (!isReloginShow) {
            isReloginShow = true;
            MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }
            ).then(() => {
                isReloginShow = false;
                localStorage.setItem("token", "");
                // 如果是登录页面不需要重新加载
                if (window.location.hash.indexOf("#/login") != 0) {
                    this.$router.push("/login");
                    next('/login')
                }
                
            }).catch(() => {
                isReloginShow = false;
            });
        }
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
        Message({
            message: res.data.msg,
            type: 'error'
        })
        return Promise.reject(new Error(res.data.msg))
    } else if (code !== 200) {
        Notification.error({
            title: res.data.msg
        })
        return Promise.reject('error')
    } else {
        return res.data
    }
}, (oError) => {
    return Promise.reject(oError);
});

// myAxios.interceptors.response.use(
//     response => {
//       if (response.data.code != 0) {
//         return Promise.reject(response);
//       }
//       return response.data
//     }
//   )


export default myAxios;