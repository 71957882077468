<template>
    <div class="login">
        <div class="title">工业生态系统</div>
        <!-- <div class="title">台州移动-工业生态系统</div> -->
        <div class="box">
            <div class="kong"></div>
            <div class="details">
                <div class="content1">
                    <div class="name">账户 </div>
                    <input type="text" v-model="account" placeholder="请输入手机号/邮箱" name="" id="">
                </div>
                <div class="content1">
                    <div class="name">密码 </div>
                    <input type="password" v-model="password" placeholder="请输入密码" name="" id="">
                </div>
                <div class="btn" @click="Loginbtn">登录</div>
                <!-- <div class="date">
                    <el-checkbox v-model="checked">记住密码</el-checkbox>
                    <a class="mima">忘记密码？</a>
                </div> -->
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    comments: {},
    props: {},
    data() {
        return {
            // account: "13703660790",
            // password: "123456",
            account: "",
            password: "",
            options: [],
            checked: false,
        };
    },
    created() { },
    mounted() {
    },
    methods: {
        async Loginbtn() {
            if (!this.account) {
                this.$message.error('请输入账号');
                return
            }
            if (!this.password) {
                this.$message.error('请输入密码');
                return
            }
            if (!this.password.length >= 4) {
                this.$message.error('密码不能小于5位');
                return
            }
            const res = await fnAxios({
                method: "post",
                url: "/pcApi/login",
                data: {
                    phonenumber:this.account,
                    password:this.password,
                },
            });
            if (res.code == 200) {
                console.log(res);
                localStorage.setItem("token", res.data);
                localStorage.setItem("phoneNum", this.account);
                this.$router.push("/home");
                this.getNewsUserId()

            }else {
                this.$message.error(res.msg)
            }
            // this.$router.push("/home");
        },
        async getNewsUserId() {
            const res = await fnAxios({
                method: "get",
                url: "/pcApi/getNewsUserId",
            });
            if (res.code == 200) {
                if (res.data == "") {
                    this.addNews();
                }
            }
        },
        async addNews() {
            const res = await fnAxios({
                method: "post",
                url: "/pcApi/addNews",
            });
            if (res.code == 200) {
            }
        },
    }
}
</script>
  
<style scoped lang="less">
.login {
    position: relative;
    width: 100%;
    height: 1080px;
    opacity: 1;
    background: #409EFF;

    .title {
        position: absolute;
        top: 111px;
        left: 50%;
        transform: translate(-50%);
        font-family: HarmonyOS_Sans_SC_Bold;
        font-size: 56px;
        font-weight: bold;
        line-height: 112px;
        text-align: center;

        /* 外部/全局/white */
        color: #FFFFFF;

        text-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .box {
        position: absolute;
        top: 98px;
        left: 50%;
        transform: translate(-50%);
        width: 1540px;
        height: 982px;
        border-radius: 53px;
        // opacity: 1;

        background: url(../../imgs/image.png) no-repeat;
        background-size: 100%, 100%;

        .kong {
            position: absolute;
            top: 293px;
            right: 430px;
            width: 63px;
            height: 65px;
            background: #FFFFFF;
        }

        .details {
            position: absolute;
            top: 360px;
            right: 450px;
            width: 266px;
            height: 316px;
            background: #FFFFFF;
            padding: 9px;

            .content1 {
                width: 100%;
                font-size: 14px;
                color: #131414;
                margin-bottom: 20px;

                .name {
                    font-family: SourceHanSansCN-Bold;
                    font-weight: bold;
                    height: 24px;
                    line-height: 24px;
                }

                input {
                    margin-top: 10px;
                    width: 226px;
                    height: 40px;
                    border-radius: 3px;
                    background: #FFFFFF;
                    box-sizing: border-box;
                    border: 1px solid #909399;
                    padding-left: 4px;
                }
            }

            .btn {
                margin-top: 36px;
                width: 226px;
                height: 40px;
                border-radius: 3px;
                opacity: 1;
                background: #409EFF;
                font-family: SourceHanSansCN-Regular;
                font-size: 10px;
                font-weight: normal;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }

            .date {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                padding-right: 15px;

                .mima {
                    font-family: SourceHanSansCN-Regular;
                    font-size: 10px;
                    font-weight: normal;
                    line-height: 19px;
                    color: #409EFF;
                }
            }
        }

        // box-shadow: 23px -7px 66px 0px rgba(0, 0, 0, 0.17);
    }
}
</style>