<template>
    <div>
        <div class="header">
            <div class="conter-box">
                <!-- <img class="logo" src="@/imgs/logo.png" alt=""> -->
                <!-- <img class="Frame" src="@/imgs/Frame.png" alt=""> -->
                <div class="Frame">
                    <div style="font-size: 26px;letter-spacing: 2px;line-height: 30px;">工业生态系统</div>
                    <div style="font-size: 14px;">Industrial Supplier Ecosystem</div>
                </div>
            </div>
            <div class="labels">
                <div v-for="(item, index) in goPageList" :key="index">
                    <div class="modular" :class="[pagename == item.id ? 'selected' : '']" @click="goPage(item.id)">{{
                        item.name
                    }}</div>
                </div>
            </div>
            <div class="rightlist">
                <el-badge :value="numList" class="item">
                    <div class="vector2" @click="vectorbtn"><img src="@/imgs/vector2.png" alt=""></div>
                </el-badge>
                <div class="user" @click="dropDownbtn">
                    <img class="img1" src="@/imgs/user.png" alt="">
                    <span class="phone">{{ phoneNum }}</span>
                    <img class="img2" src="@/imgs/drop-down.png" alt="">
                </div>
                <!-- <div class="LogOut" @click="signOut"><img src="@/imgs/Log-out.png" alt=""></div> -->
                <div class="My" :class="num == 1 ? 'out' : ''" v-if="show">
                    <div class="MyDownloads" @click="MyDownloadsbtn">我的下载</div>
                    <div class="MyDownloads" @click="MyDownloadsbtn1">退出登录</div>
                </div>
            </div>
            <div class="Popup" v-if="show2" @click="closebtn"></div>
            <div class="box" v-if="show2">
                <div class="details">
                    <div class="title">站内消息通知</div>
                    <div class="conter" v-for="(item, index) in detailsList" :key="index" v-if="index <= numberss">
                        <div class="label" style="margin-bottom: 10px;">{{ item.content }}</div>
                        <div class="label">{{ item.createTime }}</div>
                    </div>
                    <div class="SeeMore" @click="SeeMoreBtn">{{ txt }}</div>
                </div>
            </div>
        </div>
        <div class="header1"></div>
    </div>

</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            // 当前选择的菜单id
            pagename: '0',
            // 一级菜单
            goPageList: [
                {
                    id: 0,
                    name: "供应商搜索",
                },
                {
                    id: 1,
                    name: "成熟方案",
                },
                {
                    id: 2,
                    name: "学习频道",
                },
                {
                    id: 3,
                    name: "行业洞察",
                }
            ],
            // 下拉框的选择与隐藏
            show: false,
            // 当前所在页面
            CurrentPage: "",
            show1: false,
            num: "",
            show2: false,
            numList: "",//消息提示
            getMessageAll: [], //
            detailsList: [],
            numberss: "4",
            isShow: true,
            txt: "查看更多",
            phoneNum: "", //电话号码
        }
    },
    created() {
        let num = localStorage.getItem("phoneNum");
        this.phoneNum = num.substring(0, 3)+"****"+ num.substr(num.length-4)
    },
    mounted() {
        this.getTime();
        
    },
    watch: {
        "$route": {
            handler(newValue) {
                this.CurrentPage = newValue.name;
                if (newValue.name === 'information') {
                    this.pagename = 1;
                }
                if (newValue.name === 'Integration') {
                    this.pagename = 2;
                }
                if (newValue.name === 'NewsDetail') {
                    this.pagename = 3;
                }
                // if (newValue.name === 'AboutUs') {
                //     this.pagename = 0;
                // }
                if (newValue.name === 'home') {
                    this.pagename = 0;
                }
            },
            deep: true,
            immediate: true,

        }
    },
    methods: {
        goPage(id) {
            this.pagename = id;
            if (id == 0) {
                this.$router.push("/home");
            }
            if (id == 1) {
                this.$router.push("/information");
            }
            if (id == 2) {
                this.$router.push("/Integration");
            }
            if (id == 3) {
                this.$router.push("/NewsDetail");
            }
        },
        // 下拉框点击事件
        dropDownbtn() {
            this.show = !this.show;
            this.num = 0;
        },
        // 我的下载按钮
        MyDownloadsbtn() {
            console.log(this.CurrentPage);
            if (this.CurrentPage == "AboutUs") {
                this.show = false;
                return
            } else {
                this.show = false;
                this.$router.push("/AboutUs");
            }
        },
        // 退出按钮
        signOut() {
            this.show = !this.show;
            this.num = 1;
        },
        MyDownloadsbtn1() {
            // this.$store.dispatch('/' + this.CurrentPage, this.$route)
            this.$router.push("/login");
            localStorage.setItem("token", "");
        },
        // 打开弹框
        async vectorbtn() {
            this.show2 = true;
            const res = await fnAxios({
                method: "get",
                url: "/pcApi/getMessageAll",
            });
            if (res.code == 200) {
                console.log(res);
                this.detailsList = res.data
                this.updateNews();
                this.numList = "";
                // this.getMessageCount();
            }
        },
        // 关闭弹框
        closebtn() {
            this.show2 = false;
        },
        async updateNews() {
            var date = new Date();
            var year = date.getFullYear();
            var aa = date.getMonth() + 1;
            var month = aa < 10 ? "0" + aa : aa;
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            var currentTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            const res = await fnAxios({
                method: "post",
                url: "/pcApi/updateNews",
                data: {
                    time: currentTime,
                }
            });
            if (res.code == 200) {
                console.log(res);
            }
        },
        async getMessageCount() {
            var date = new Date();
            var year = date.getFullYear();
            var aa = date.getMonth() + 1;
            var month = aa < 10 ? "0" + aa : aa;
            var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            var currentTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            const res = await fnAxios({
                method: "post",
                url: "/pcApi/getMessageCount",
                date: {
                    time: currentTime,
                }
            });
            if (res.code == 200) {
                this.numList = res.data;
                if (res.data >= 1) {
                    this.numList = res.data;
                } else {
                    this.numList = "";
                }
            }
        },
        async getTime() {
            const res = await fnAxios({
                method: "get",
                url: "/pcApi/getTime",
            });
            if (res.code == 200) {
                console.log(res.data[0]);
                if (res.data[0] == null) {
                    this.selectCountAll();
                } else {
                    this.getMessageCount();
                }
            }
        },
        async selectCountAll() {
            const res = await fnAxios({
                method: "get",
                url: "/pcApi/getMessageCountAll",
            });
            if (res.code == 200) {
                console.log(res);
                this.numList = res.data
            }
        },
        // 点击查看更多
        SeeMoreBtn() {
            this.isShow = !this.isShow;
            this.numberss = this.isShow ? 4 : this.detailsList.length;
            this.txt = this.isShow ? '查看更多' : '收起'
        },
    }
}
</script>

<style scoped lang="less">
.header1 {
    width: 100%;
    height: 80px;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1450px;
    height: 80px;
    opacity: 1;
    z-index: 33;

    /* 自动布局 */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 32px;

    /* 外部/全局/white */
    background: #FFFFFF;

    .conter-box {
        width: 424px;
        height: 74px;
        opacity: 1;
        /* 自动布局 */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 12px;
        z-index: 0;

        .logo {
            width: 75px;
            height: 68px;
        }

        .Frame {
            font-family: SourceHanSansCN-Regular;
            font-weight: 500;
            line-height: 24px;
            width: 300px;
            height: 74px;
            white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            // padding-top: 12px;
        }
    }

    .labels {
        display: flex;
        cursor: pointer;

        .modular {
            width: 260.5px;
            height: 80px;
            opacity: 1;

            /* 自动布局 */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 20px;
            gap: 8px;
            flex-grow: 1;
            align-self: stretch;

            /* 外部/全局/white */
            background: #FFFFFF;

            z-index: 1;
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;

            /* 外部/Font/text-primary */
            color: #131414;

        }

        .selected {
            width: 260.5px;
            height: 80px;
            opacity: 1;

            /* 自动布局 */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 20px;
            gap: 8px;
            flex-grow: 1;
            align-self: stretch;

            /* 外部/全局/white */
            background: #FFFFFF;

            box-shadow: inset 0px -4px 0px 0px #409EFF;

            z-index: 0;
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;

            /* 外部/Font/text-primary */
            color: #409EFF;
        }
    }

    .rightlist {
        position: relative;
        display: flex;
        align-items: center;
        width: 364px;
        height: 80px;


        .vector2 {
            margin-left: 57px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .user {
            display: flex;
            align-items: center;
            margin-left: 54px;

            .img1 {
                width: 24px;
                height: 24px;
                margin-right: 7px;
                cursor: pointer;
            }

            .phone {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                font-family: HarmonyOS_Sans_SC;
                font-size: 14px;
                font-weight: normal;
                color: #C0C4CC;
                margin-right: 7px;
                cursor: pointer;
            }

            .img2 {
                width: 14px;
                height: 8px;
                cursor: pointer;
            }
        }

        .LogOut {
            width: 25px;
            height: 25px;
            margin-left: 45px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .My {
            position: absolute;
            top: 80px;
            right: 100px;
            width: 130px;
            height: auto;
            background: #FFFFFF;
            z-index: 33;

            .MyDownloads {
                width: 100%;
                height: 40px;
                font-family: SourceHanSansCN-Medium;
                font-size: 18px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0px;
                text-align: center;
                color: #606266;
                cursor: pointer;
            }

            .MyDownloads:hover {
                background: #000;
                color: #fff;
            }
        }

        .out {
            position: absolute;
            top: 80px;
            right: -30px !important;
            width: 130px;
            height: auto;
            background: #FFFFFF;
            z-index: 33;
        }
    }

    .Popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 60%);
    }

    .box {
        position: fixed;
        top: 200px;
        left: 50%;
        transform: translate(-50%);
        width: 510px;
        min-height: 583px;
        padding: 18px 15px;
        background: #fff;
        z-index: 111;
        border-radius: 10px;

        .details {
            position: relative;
            width: 479px;
            height: 547px;
            overflow: auto;
            // border: 1px solid #cfd3d5;
            border-radius: 10px;

            .title {
                width: 100%;
                height: 55px;
                line-height: 55px;
                font-size: 24px;
                background: #f4f6f7;
                padding-left: 10px;
                border-bottom: 1px solid #f4f6f7;
                color: #626060;
            }

            .conter {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                height: 85px;
                border-bottom: 2px solid #cfd3d5;
                padding-left: 10px;

                .label {
                    width: 100%;
                    font-size: 18px;
                    background: #fff;
                    font-weight: 400;
                    overflow: hidden; //超出的文本隐藏
                    text-overflow: ellipsis; //溢出用省略号显示
                    white-space: nowrap; //溢出不换行
                    color: #7b7777;
                }
            }

            .SeeMore {
                // position: absolute;
                // bottom: 0;
                // left: 0;
                width: 100%;
                height: 55px;
                line-height: 55px;
                border-bottom: 2px solid #cfd3d5;
                padding-left: 10px;
                font-size: 18px;
                font-weight: 400;
                color: #7b7777;
                cursor: pointer;
            }
        }
    }
}
</style>